import { Injectable } from '@angular/core';
import { SnackbarService } from 'apps/early-stage-office/src/app/utility-modules/snackbar/services/snackbar.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class StateErrorHandlerService {
    constructor(public snackbar: SnackbarService) {}

    public parseError(e: HttpErrorResponse, all = false): void {
        if (!navigator.onLine) {
            const error = 'Wystąpił problem z połączeniem internetowym';
            this.message(error);

            return;
        }

        if (e.status === 500 || e.status === 404 || !e.status || all) {
            const error =
                e && e.error && e.error.errors && e.error.errors.general
                    ? e.error.errors.general
                    : 'Spróbuj ponownie później lub/i skontaktuj się z administratorem. ';

            this.message(error);
        }
    }

    public message(text: string, title: string = 'Wystąpił błąd'): void {
        this.snackbar.add({
            title,
            text,
            type: 'error',
        });
    }
}
